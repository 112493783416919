import escapeRegExp from 'lodash.escaperegexp'

import { NetworkPublicInfo } from '@tribeplatform/gql-client/types'

import { getDomainInfo, getOriginURLs } from '../../common/utils/domain.js'

export const parseHref = (
  href: string,
  networkPublicInfo: NetworkPublicInfo,
) => {
  const { subfolder, domain } = getDomainInfo(
    networkPublicInfo?.domain,
    networkPublicInfo?.domainSubfolder,
  )
  if (!href) {
    return '/'
  }

  const originURLs = getOriginURLs(networkPublicInfo?.domain, domain, subfolder)
  const hrefWithProtocol = addProtocol(href)
  const hrefWithoutOriginURLs = removeOriginURLs(hrefWithProtocol, originURLs)

  return hrefWithoutOriginURLs
}

export const getLinkRel = (target: string, isTrusted: boolean) => {
  const isBlank = target === '_blank'
  const blankRel = isBlank ? 'noopener noreferrer' : ''
  const trustRel = !isTrusted ? 'nofollow ugc' : ''
  const linkRel = `${blankRel} ${trustRel}`

  return linkRel.trim()
}

export const getLinkFeatures = (target: string) => {
  const isBlank = target === '_blank'

  if (isBlank) {
    return 'noopener,noreferrer'
  }

  return ''
}

export const getIsInternal = (href: string) => {
  if (href.indexOf('?') === 0) {
    return true
  }
  if (href.indexOf('#') === 0) {
    return true
  }
  if (href.indexOf('/') === 0 && href.indexOf('//') !== 0) {
    return true
  }

  return false
}

export const getLinkTarget = (target: string, isInternal: boolean) => {
  if (!target) {
    return isInternal ? '' : '_blank'
  }

  return target
}

const addProtocol = (href: string) => {
  const isInternal = getIsInternal(href)
  if (isInternal) {
    return href
  }

  const protocolRegExp = new RegExp('^(http(s)?:\\/\\/|\\/\\/|mailto:|tel:|#)')
  if (protocolRegExp.test(href)) {
    return href
  }

  return `https://${href}`
}

const removeOriginURLs = (href: string, originURLs: string[]) => {
  const escapedOriginURLs = originURLs.map(url => escapeRegExp(url))
  const hrefWithoutOriginURLs = href.replace(
    new RegExp(`^(http(s)?:\\/\\/|\\/\\/)(${escapedOriginURLs.join('|')})`),
    '',
  )

  return hrefWithoutOriginURLs || '/'
}
