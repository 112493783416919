import { clsx } from 'clsx'

import { TITLE_FIELD_KEY } from '@bettermode/common/CMS'
import { Post } from '@tribeplatform/gql-client/types'
import {
  PostHiddenIndicator,
  PostLockedIndicator,
} from '@tribeplatform/react-components/Post'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'
import { Text } from '@tribeplatform/react-ui-kit/Text'
import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'

import { getLinkToUrl } from '../utils/index.js'

const getPostTitle = (post: Post) => {
  const titleField = post?.fields?.find(field => field.key === TITLE_FIELD_KEY)
  const nativePostTitle = post?.title

  if (!titleField) {
    return nativePostTitle
  }

  try {
    const title = JSON.parse(titleField.value) as string
    return title
  } catch (error) {
    logger.error('Failed to parse title field value')
    return nativePostTitle
  }
}

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  stretchX: string
  titleVariant: TextVariant
  linkTo: string
  preview: boolean
  isSinglePost: boolean
  isPostPage: boolean
}
export const PostTitle = ({
  field,
  post,
  highlighted,
  stretchX,
  titleVariant,
  linkTo,
  preview,
  isSinglePost,
  isPostPage,
}: Props) => {
  const { Link } = useRouter()
  const linkToUrl = getLinkToUrl({ linkTo, post })
  const { default: defaultTitle } = field
  const postTitle = getPostTitle(post)
  const title = postTitle || (preview ? defaultTitle : '')
  const isHidden = post?.isHidden
  const isLocked = post?.locked
  const titleHeadingSize = isPostPage ? 'h1' : 'h2'

  if (isSinglePost && isPostPage) {
    return (
      <div
        className={clsx(
          stretchX,
          'min-w-0 break-words',
          highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        )}
      >
        <Text as={titleHeadingSize} variant={titleVariant}>
          {isHidden && <PostHiddenIndicator />}
          {isLocked && <PostLockedIndicator />}
          {title}
        </Text>
      </div>
    )
  }

  return (
    <Link
      href={linkToUrl}
      variant="inherit"
      className={clsx(stretchX, 'break-words min-w-0 inline-block')}
      onClick={e => e.stopPropagation()}
    >
      <Text
        as={titleHeadingSize}
        variant={titleVariant}
        className={clsx(
          'line-clamp-3',
          highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        )}
      >
        {isHidden && <PostHiddenIndicator />}
        {isLocked && <PostLockedIndicator />}
        {title}
      </Text>
    </Link>
  )
}
