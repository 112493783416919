import {
  ReactNode,
  FC,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
  type ComponentProps,
} from 'react'

import { Navigate, useLocation, useNavigate, useParams } from 'react-router'

import { useDomain } from '@tribeplatform/react-components/common/hooks'
import { Link } from '@tribeplatform/react-components/Link'
import { TribeRouter, type TribeRouterType } from '@tribeplatform/react-sdk'

import type { OTSM } from '../server/types/server.types.js'
import { Otsm } from './components/Otsm.js'
import { useAnalytics } from './hooks/useAnalytics.js'
import { useWindowTribe } from './hooks/useWindowTribe.js'
import {
  TribeEmitter,
  TribeEmitterEvent,
} from './lib/tribe-events/TribeEmitter.js'

type TribeWebProps = {
  otsm: OTSM
  children: ReactNode
}

export const TribeWeb: FC<TribeWebProps> = ({ otsm, children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const previousPage = useRef<string>(location.pathname)

  useWindowTribe()
  const { subfolder } = useDomain()

  const analytics = useAnalytics()

  useEffect(() => {
    analytics.trackPageView(location.pathname)
  }, [location.pathname, analytics])

  // emit an event on route change
  useEffect(() => {
    const page = location.pathname
    TribeEmitter.emit(TribeEmitterEvent.PAGE_VIEW, {
      page,
      previousPage:
        previousPage.current !== page ? previousPage.current : undefined,
    })

    previousPage.current = page
  }, [location.pathname])

  // clear listeners on component unmount
  useEffect(
    () => () => {
      TribeEmitter.clearListeners(TribeEmitterEvent.PAGE_VIEW)
    },
    [],
  )

  return (
    <TribeRouter
      {...{
        location,
        params,
        Link: forwardRef<
          HTMLAnchorElement,
          ComponentProps<TribeRouterType['Link']>
        >(({ children, ...props }, ref) => (
          <Link ref={ref} {...props}>
            {children}
          </Link>
        )),
        Redirect: props => <Navigate to={props.to} {...props} />,
        redirect: useCallback(
          href => {
            const isInternal =
              href && href?.indexOf('/') === 0 && href?.indexOf('//') !== 0

            if (isInternal) {
              window.location.href = `${subfolder}${href}`
            } else {
              window.location.href = href
            }
          },
          [subfolder],
        ),
        push: useCallback(
          path => {
            navigate(path)
          },
          [navigate],
        ),
        replace: useCallback(
          path => {
            navigate(path, { replace: true })
          },
          [navigate],
        ),
      }}
    >
      {children}
      {otsm && <Otsm otsm={otsm} />}
    </TribeRouter>
  )
}
