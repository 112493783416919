import type { IntlShape } from 'react-intl/src/types.js'

import { getDomainInfo } from '@tribeplatform/react-components/common/utils'

import type { InjectedAppProps } from '../../../server/types/server.types.js'
import { App } from '../../App.js'
import { RouteGuard } from './RouteGuard.js'

interface Props {
  i18n: IntlShape
  injectedAppProps: InjectedAppProps
}
export const GuardedClientApp = ({ i18n, injectedAppProps }: Props) => {
  const { domain, domainSubfolder } = injectedAppProps?.network
  const { subfolder } = getDomainInfo(domain, domainSubfolder)
  const roleType = injectedAppProps?.authMember?.role?.type
  const visibility = injectedAppProps?.network?.visibility
  const emailStatus = injectedAppProps?.authMember?.emailStatus
  const memberId = injectedAppProps?.authMember?.id

  return (
    <>
      {/* The client side route guards require a data router to be present.
       * Since the server side router is static and doesn't support some of the
       * API used for client side logic, we are wrapping the app in a separate component
       * for client to inject the guards. The proper solution to this requires to fully migrate
       * to the data compatible version of the StaticRouter which is going to affect our data fetching
       * and rendering paradigms.
       */}
      <RouteGuard
        subfolder={subfolder}
        roleType={roleType}
        visibility={visibility}
        emailStatus={emailStatus}
        memberId={memberId}
      />
      <App i18n={i18n} injectedAppProps={injectedAppProps} />
    </>
  )
}
