import '../window-logger.js'

import { createRoot, hydrateRoot } from 'react-dom/client'

import { getDomainInfo } from '@tribeplatform/react-components/common/utils'

import { APP_ROOT_ELEMENT_ID } from '../constants/app.constants.js'
import { polyfillJsFeatures } from '../lib/js-polyfills.js'
import { intlPolyfill } from '../utils/intlPolyfill.js'
import { localizeLibsForClient } from '../utils/localizeLibs.js'
import { ClientApp } from './components/ClientApp.js'
import { getInjectedAppProps, getTranslationDate } from './utils/entry.utils.js'

const render = async () => {
  await polyfillJsFeatures()

  const container = document.getElementById(APP_ROOT_ELEMENT_ID)
  /**
   * The `isPWA` and `isEmbed` variables are added
   * to the window via a server-injected head script.
   * See server.entry.tsx for more details
   */
  // eslint-disable-next-line dot-notation
  const isPWA = window['isPWA'] === true
  // eslint-disable-next-line dot-notation
  const isEmbed = window['isEmbed'] === true

  const { lang } = document.documentElement

  const injectedAppProps = getInjectedAppProps()

  if (!injectedAppProps) {
    throw new Error('Missing initial app data')
  }

  const networkDomain = injectedAppProps?.network?.domain
  const networkDomainSubfolder = injectedAppProps?.network?.domainSubfolder

  if (!networkDomain) {
    throw new Error('Missing initial app data')
  }

  const { subfolder } = getDomainInfo(networkDomain, networkDomainSubfolder)
  const i18n = await getTranslationDate(lang, subfolder)

  if (!i18n) {
    throw new Error('Missing translation data')
  }

  await Promise.all([intlPolyfill(lang), localizeLibsForClient(lang)])

  if (isPWA) {
    const root = createRoot(container)
    root.render(
      <ClientApp
        i18n={i18n}
        isEmbed={isEmbed}
        injectedAppProps={injectedAppProps}
      />,
    )
  } else {
    // Load all components needed before rendering
    hydrateRoot(
      container,
      <ClientApp
        i18n={i18n}
        isEmbed={isEmbed}
        injectedAppProps={injectedAppProps}
      />,
      {
        // The type for onRecoverableError is wrong and doesn't include the errInfo param
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRecoverableError: (error: unknown, errInfo: unknown) => {
          logger.error('Received a hydration error', {
            error,
            errInfo,
          })
        },
      },
    )
  }
}

render()
