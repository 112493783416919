import type { IntlShape } from 'react-intl/src/types.js'
import { createMemoryRouter, Route } from 'react-router'
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'

import { getDomainInfo } from '@tribeplatform/react-components/common/utils'

import type { InjectedAppProps } from '../../../server/types/server.types.js'
import { GuardedClientApp } from './GuardedClientApp.js'

interface Props {
  i18n: IntlShape
  injectedAppProps: InjectedAppProps
  isEmbed: boolean
}
export const ClientApp = ({ i18n, injectedAppProps, isEmbed }: Props) => {
  const {
    network: { domain, domainSubfolder },
  } = injectedAppProps
  const { basename } = getDomainInfo(domain, domainSubfolder)
  const routes = createRoutesFromElements(
    <Route
      path="*"
      element={
        <GuardedClientApp i18n={i18n} injectedAppProps={injectedAppProps} />
      }
    />,
  )

  const router = isEmbed
    ? createMemoryRouter(routes, {
        basename,
        initialEntries: [
          `${window.location.pathname}${window.location.search}`,
        ],
      })
    : createBrowserRouter(routes, { basename })

  return <RouterProvider router={router} />
}
